export const cardData = [
  {
    cardTitle: 'Rakuten Kobo',
    job: 'React Native',
    period: '08/2024 - Present',
    description:
      'Currently implementing an improved reader experience on an E-reading app with over 1 Million downloads. Tech stack includes React Native, React Navigation, and Redux. I am also gaining experience in managing third party dependencies and integrating native modules.',
  },
  {
    cardTitle: 'Salt & Pepper Solutions',
    job: 'React Native',
    period: '04/2022 - 06/2024',
    description:
      'Maintained an app in production with over 10K downloads on Android and iOS and a 4.8 star average rating in each store. Played a key role as a team member on two React Native MVPs. Worked on almost every aspect of these projects, creating layouts, managing state with Redux Tookit, and implementing React Navigation. Gained experience releasing test builds for Android and iOS. Given the small team on one project, prioritized tasks and provided guidance to an intern in the absence of a tech lead. Used TypeScript and wrote unit tests with Jest for all projects.',
  },
  {
    cardTitle: 'Mobius Innovations',
    job: 'React & React Native',
    period: '04/2023 - 09/2023',
    description:
      'Built an admin dashboard with React and MUI for a US-based start-up in the health and wellness industry. Worked independently, planning and organizing the project from the very beginning with minimal guidance. Set up the state management with Redux and implemented an API service for network requests. Completed feature work on the React Native app in order to help the team meet deadlines. Leaned on my experience using the React Native CLI to solve Android specific build issues that would have delayed the project.',
  },
  {
    cardTitle: 'Insurance Business Applications',
    job: 'Frontend / React',
    period: '10/2021 - 03/2022',
    description:
      'Worked on a team implementing a new FE design for a large SaaS platform. Created new React components, adhering to strict coding standards. Focused on building highly flexible and reusable components. Learned to use Storybook. Gained experience with CSS modules and the BEM naming convention. Wrote unit tests with Jest. Used Agile and Scrum.',
  },
  {
    cardTitle: 'Bookit Sports',
    job: 'React Native',
    period: '02/2020 - 07/2021',
    description:
      'Helped an early stage sports media startup obtain a six figure seed investment, working as part of a team that built a React Native prototype. Built a variety of screens and components. Gained experience with Redux and React Navigation. Stepped up and performed a variety of QA roles given the small team we had. Continued to work through the MVP release. Surpassed 10,000 unique signups!',
  },
  {
    cardTitle: 'CodeCrush.io',
    job: 'React',
    period: '12/2019 - 07/2020',
    description:
      'Assumed the role of a teaching assistant, pair programming with two non-traditional students learning React and Redux. Refactored sample code used in training projects. Worked with backend technologies such as MongoDB, ExpressJS, and NodeJS. Sharpened interpersonal communication skills and strengthened my ability to explain fundamental concepts clearly.',
  },
];
